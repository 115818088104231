export default class CDNUtils {

    public static usingCDN(): boolean {
        let wp: string = window.location.hostname;
        return CDNUtils.isLgtest(wp) || CDNUtils.isZazer(wp);
    }

    public static getStaticUrl(withSlash: boolean): string {
        let wp: string = window.location.hostname;
        let baseUrl = '/';
        if (CDNUtils.isLgtest(wp)) {
            baseUrl = 'https://static.lgtest.fun';
        } else if (CDNUtils.isZazer(wp)) {
            baseUrl = 'https://static.zazer.mobi';
        }
        return withSlash ? (baseUrl + '/') : baseUrl;
    }

    private static isLgtest(wp: string) {
        return 'lgtest.fun' === wp;
    }

    private static isZazer(wp: string) {
//        return false;
        return 'ok.zazer.mobi' === wp
            || 'thor.zazer.mobi' === wp
            || 'ya.zazer.mobi' === wp
            || 'vk.zazer.mobi' === wp
            || 'mystore.zazer.mobi' === wp
            || 'foto.zazer.mobi' === wp
            || 'exe.zazer.mobi' === wp
            || 'beeline.zazer.mobi' === wp
            || 'alex.zazer.mobi' === wp
            || 'zazer.mobi' === wp;
    }
}