import {PriorityType} from "@/utils/load-utils";

export abstract class GameState {
    public static LOADED_PCT: number = 0;

    public static REQUEST_REFRESH_NAV_PANEL: boolean = false;

    public static BATTLE_RESOURCES_LOADED: boolean = false;

    public static CREATURE_RESOURCES_LOADED: boolean = false;

    public static CLAN_RESOURCES_LOADED: boolean = false;
    public static CLAN_BACK_KEY: string = '';

    public static loadingType: PriorityType | undefined = undefined;

    public static ANIMS_LOADED: boolean = false;

    public static CLOUDS_SHOWN_FOR_SCREEN: Map<string, boolean> = new Map();

    public static showSulfur: boolean = false;
    public static showCaviar: boolean = false;
    public static showEventValue: boolean = true;

    public static PAYMENTS_CHECKED: boolean = false;

    public static REQUIRE_KONG_LOGIN: boolean = false;

    public static REQUIRE_CRAZY_LOGIN: boolean = false;

    public static YAN_NAME: string = '';
}
